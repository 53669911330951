

export const  darkTheme={
    colorBgContainer:"#1e1e1e",
    components: {
      Button: {
        colorPrimary: '#333333',
        algorithm: true, // Enable algorithm
      },
      Input: {
        colorPrimary: '#2d2d2d',
        algorithm: true, // Enable algorithm
      },
      Layout:{
        triggerBg:'#1e1e1e',
        bodyBg	:'#1e1e1e'
      },
      
      Menu:{
        itemActiveBg:"#F3FAFF",
        itemBorderRadius:4,
        itemSelectedColor:'white',
        itemColor:'#5D7285',
        itemHeight	:"64px",
        iconSize	:'32px',
        fontSizeLG:"16px",
        siderBg:"#2d2d2d"
      },
      Modal:{
        titleFontSize	:"32px",
      },
      Input:{
        inputFontSize:"13px",
        paddingBlock:"20px",
        paddingInline:"20px",
        activeBorderColor:"#C2C2C2",
        borderRadius:"12px",
      },
      InputNumber:{
        inputFontSize:"13px",
        paddingBlock:"20px",
        paddingInline:"20px",
        activeBorderColor:"#C2C2C2",
        borderRadius:"12px",
      },
      Form:{
        labelColor:"white",
        labelFontSize:"16px",
        labelHeight	:"20px",
        labelRequiredMarkColor	:"#F42323",
      },
      Table:{
        headerBg:"#F4F4F5",
        headerColor:"#71717A",
        cellFontSize	:"16px",
        cellPaddingBlock	:"10px"
      }

    },
  }